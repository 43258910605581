div#footer-container {
    padding-top: 0.8rem;
    font-size: 1.4rem;
    display: grid;
    grid-template-columns: 50% 50%;
    border-bottom: 1px solid rgb(200, 209, 224);

}

div#excelDownload {
    padding-top: 0.4rem;
    padding-left: 0.5rem;
    padding-bottom: 0.3rem;
    text-align: left;
    vertical-align: top;
}

div#footer {
    padding-top: 0.8rem;
    padding-left: 0.5rem;
    font-size: 1.31rem;
    text-align: left;
    vertical-align: top;
    white-space:pre-wrap;
}

div#buildDetails {
    padding-right: 0.05rem;
    padding-top: 0.4rem;
    text-align: right;
    font-size: small;
    font-style: oblique;
    vertical-align: baseline;
}