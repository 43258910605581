div#metrics-data-table,div.metrics-data-table {
    max-width: 1200px;
    display: grid;
    grid-template-columns: 28% 18% 18% 18% 18%;
}

#periodRange-0,
#periodRange-1,
#periodRange-2,
#periodRange-3 {
    text-align: right;
}

.iterationmetrics-sectionheader {
    text-transform: uppercase;
    height: 2.1rem;
    font-family: "ProximaNovaSemiBold", "helvetica", sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    background-color: rgb(255,255,255);
    padding-left: 0.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.2rem;
    color: rgb(51, 56, 64);
    vertical-align: baseline;
    grid-column: 1 / span 5;
}

.row-label,.data-label {
    text-align: left;
    font-size: 1.4rem;
    background-color: rgb(255,255,255);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-weight: bold;
    vertical-align: bottom;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-bottom: 2px solid rgb(245, 247, 250);
}

.data-numeric {
    font-size: 1.4rem;
    color: rgb(51, 56, 64);
    background-color: rgb(255,255,255);
    text-align: right;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-right: 0.5rem;
    border-bottom: 2px solid rgb(245, 247, 250);
}

.bottom-row{
    border-bottom-style: none;
}

.last-col{
    padding-right: 0.5rem;
}

.thick-border-top, .iterationmetrics-sectionheader {
    border-top: solid 0.2rem rgb(200, 209, 224);
}

.thick-border-bottom, .iterationmetrics-sectionheader {
    border-bottom: solid 0.2rem rgb(200, 209, 224);
}