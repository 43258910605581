.data-tooltip {
    color: rgb(50, 124, 152);
}

.tooltip-highlight {
    font-weight: bold;
    color: yellow;
    text-transform: uppercase;
}

.tippy-tooltip {
    background-color: rgb(50, 124, 152);
}

.tippy-tooltip[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: rgb(50, 124, 152);
}

.tippy-tooltip[data-placement^="bottom"] > .tippy-arrow::before {
    border-bottom-color: rgb(50, 124, 152);
}

.tippy-tooltip[data-placement^="left"] > .tippy-arrow::before {
    border-left-color: rgb(50, 124, 152);
}

.tippy-tooltip[data-placement^="right"] > .tippy-arrow::before {
    border-right-color: rgb(50, 124, 152);
}

.tippy-box > .tippy-svg-arrow {
    fill: rgb(50, 124, 152);
}