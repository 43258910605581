.App {
  text-align: center;
  font-family: "Open Sans",-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
  color: rgb(51, 56, 64);
  background-color: rgb(245, 247, 250);

}

.pdo-metrics{
  text-align: left;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 50px auto 50px;

  position: absolute;
  top:0;
  left: 0;
  right: 0;

  margin: auto;
}

#topBar {
  display: grid;
  grid-template-columns: 75% 25%;
}

#topBarRight{
  text-align: right;
  font-weight: 700;
  font-size: 1.4rem;
  /*position: relative;*/
}

.itms-data{
  font-size: 10px;
  text-align: left;
}

.itms-list{
  font-size: 10px;
  text-align: left;
  font-style: normal;
}



