#PeriodSelector {
    padding: .5rem .5rem .5rem .5rem;
    font-weight: 700;
    font-size: 1.4rem;
}

.periodSelectorPaddingLeft{
    margin-left: 1rem;
    font-size: 1.4rem;
}

#periodEndDate{
    width: 10rem;
    text-align: center;
}

#periodLength{
    width: 3rem;
    text-align: center;
}

