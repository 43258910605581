.dataRetrieval,.dataRetrievalError {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding-right: 0.5rem;
}

.dataRetrievalError{
    color: #ff0000;
    font-weight: bold;
}

.bi,.bi-question-circle-fill{
    fill: rgb(50, 124, 152);
}

th.dataRetrievalEvents{
    font-weight: bold;
    text-decoration: underline;
    text-align: left;
}
td.dataRetrievalEvents,th.dataRetrievalEvents{
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}