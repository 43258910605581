.banner-container {
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(204, 204, 204, 1);
    border-bottom: 1px solid rgba(204, 204, 204, 1);
    border-left: 1px solid rgba(204, 204, 204, 1);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.banner-item {
    width: 20%;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgba(204, 204, 204, 1);
    height: 120px;
}

.banner-visual {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.banner-lead,
.banner-cycle {
    font-size: 6.4rem;
}

.banner-lead-trend,
.banner-cycle-trend {
    font-size: 4.8rem;
}

.banner-label {
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 20px;
    font-weight: bold;
    font-size:1.2rem;
    color: rgb(51, 56, 64);
}
.banner-metric {
    font-size: 6.4rem;
    color: rgb(51, 56, 64);
}

.banner-metric-trend {
    font-size: 4.8rem;
}

.insight-good {
    color: #60b044;
}

.insight-bad {
    color: #ff0000;
}

.insight-neutral {
    color: #c0c0c0;
}

.c3-chart-arcs-background {
    fill: #e0e0e0;
    stroke: none;
}